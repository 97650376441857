import type { AsyncNamespace, EventTopic, OttoNexus } from "@otto-ec/global-resources/nexus";
import { otto } from "@otto-ec/global-resources/nexus";

declare global {
	interface NexusInterfaces {
		ft9: {
			benefit: {
				tag: AsyncNamespace<{
					mergeTracking: EventTopic<{
						elementId: string;
						parentId: string;
					}>;
				}>;
			};
		};
	}
}

export type BenefitTag = OttoNexus["ft9"]["benefit"]["tag"];
export const benefitTag: BenefitTag = otto.ft9.benefit.tag;
